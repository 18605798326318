<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {getAction} from "@/utils/http"
export default {
  data () {
    return {
      url:'/trans/total',
    }
  },
  created (){
    console.log("App created");
    getAction(this.url).then(res=>{
      console.log("app res:",res);
      sessionStorage.setItem("map",'bmap');
    });
  },
  mounted(){
    console.log("App mounted")
  },
}
</script>
<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #111168;
  overflow: hidden;
}
</style>
