//import Vue from 'vue'
import axios from 'axios'


axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// // 创建 axios 实例
// const requests = axios.create({
//   baseURL: process.env.VUE_APP_API_BASE_URL, // 基础url,如果是多环境配置这样写，也可以像下面一行的写死。
//   timeout: 6000 // 请求超时时间
// })

// // response interceptor（接收拦截器）
// requests.interceptors.response.use((response) => {
//   const res = response.data
//   console.log("拦截器：",res)
//   if (res.code !== 0&&res.code!==200) { 
//     // Notify({ type: 'danger', message: res.message||res.msg });
//     // // 401:未登录;
//     // if (res.code === 401||res.code === 403||res.code===999) {
//     //   Notify({ type: 'danger', message: '请登录'});
//     // }
//     return Promise.reject('error')
//   } else {
//     return res
//   }
// })

//post
export function postAction(url,parameter) {
    return axios({
      url: url,
      method:'post' ,
      data: parameter
    })
  }
  
  //post method= {post | put}
  export function httpAction(url,parameter,method) {
    return axios({
      url: url,
      method:method ,
      data: parameter
    })
  }
  
  //put
  export function putAction(url,parameter) {
    return axios({
      url: url,
      method:'put',
      data: parameter
    })
  }
  
  //get
  export function getAction(url,parameter) {
    return axios({
      url: url,
      method: 'get',
      params: parameter
    })
  }
  
  //deleteAction
  export function deleteAction(url,parameter) {
    return axios({
      url: url,
      method: 'delete',
      params: parameter
    })
  }